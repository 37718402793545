import { Dialog , Transition } from '@headlessui/react'
import { Fragment, useRef, useEffect } from 'react'

type TermsType = {
    termsOpen: boolean
    setTermsOpen?: any
}

const Terms = ({ termsOpen, setTermsOpen }: TermsType) => {
    const dialogRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        console.log(termsOpen)
        if (dialogRef.current) {
          dialogRef.current.scrollTop = 0
        }
      }, [termsOpen])

        return (
        <Transition appear show={termsOpen} as={Fragment}>
            <Dialog 
                as="div" 
                className="relative top-0" 
                onClose={() => setTermsOpen(false)} 
                ref={dialogRef}
                initialFocus={dialogRef}
            >
                termsOpen && <>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25 z-50"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto bottom-5 top-5 z-50 ">
                        <div className="flex items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="modal w-full max-w-2xl transform  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all overflow-y-scroll max-h-[500px] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <h3
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                Terms of Use
                            </h3>
                            <div className="mt-2">
                                <div className="text-sm text-gray-500 leading-6">
                                    <p>Welcome to Seven Sleeves LLC, doing business as Modern Day (hereinafter referred to as “Company” or “We” or “Us” or “Our”) which provides short-term rental property management services. These Terms of Use (hereinafter referred to as “Agreement”) govern your access and use of the short-term rental property management services provided by the Company through its website (hereinafter referred to as the “Service”).</p>
                                    <br />
                                    <p>By accessing and using the Service, you agree to be bound by the terms and conditions set forth in this Agreement. If you do not agree to these terms, you should not use the Service.</p>
                                    <ol className='list-inside list-decimal'>
                                        <br />
                                        <li className='font-bold'>Service</li>
                                        <p>The Service provided by the Company is a property management service that connects property owners with renters who are looking for short-term rentals. The Company acts as a facilitator and provides services such as marketing, guest communication, reservations, and management of the property.</p>

                                        <br />
                                        <li className='font-bold'>Eligibility</li>
                                        <p>To use the Service, you must be at least 18 years of age. By using the Service, you represent and warrant that you are at least 18 years old.</p>

                                        <br />
                                        <li className='font-bold'>Registration</li>
                                        <p>In order to use the Service, you may be required to register with the Company and create an account. You must provide accurate and complete information during the registration process. You are responsible for maintaining the confidentiality of your account login information and for any activity that occurs under your account.</p>

                                        <br />
                                        <li className='font-bold'>Use of the Service</li>
                                        <p>You may only use the Service for lawful purposes and in accordance with this Agreement. You may not use the Service in any way that violates any applicable federal, state, local, or international law or regulation.</p>

                                        <br />
                                        <li className='font-bold'>Listing and Renting Properties</li>
                                        <ol>
                                            <li className='md:ml-4 font-bold'>a. Short-term and mid-term rentals</li>
                                            <ul className='md:ml-8 list-inside list-disc'>
                                                <li>Property owners are responsible for providing accurate and complete information about their properties, including descriptions, amenities, pricing, and availability.</li>
                                                <li>Renters are responsible for ensuring they meet the eligibility criteria and requirements set forth by the property owner.</li>
                                                <li>Both property owners and renters are responsible for complying with all applicable laws and regulations related to rental transactions.</li>
                                            </ul>
                                            <li className='md:ml-4 font-bold'>b. Property descriptions and accuracy</li>
                                            <ul className='md:ml-8 list-inside list-disc'>
                                                <li>Property owners must ensure that their property listings are accurate, up-to-date, and do not contain any misleading information.</li>
                                                <li>The Company is not responsible for any discrepancies between the property description and the actual property, but will make reasonable efforts to address any reported inaccuracies.</li>
                                            </ul>
                                            <li className='md:ml-4 font-bold'>c. Pricing and fees</li>
                                            <ul className='md:ml-8 list-inside list-disc'>
                                                <li>Property owners are responsible for setting the pricing for their rentals, including any additional fees (e.g., cleaning fees, security deposits, etc.).</li>
                                                <li>The Company may charge fees for its services, as mentioned in the "Fees" section of this Agreement.</li>
                                                <li>Renters are responsible for paying the rental fees and any additional fees associated with their stay.</li>
                                            </ul>
                                        </ol>
                                        
                                        <br />
                                        <li className='font-bold'>Payments, Cancellations, and Refunds</li>
                                        <div className='md:ml-4'>
                                            <ul className='list-inside list-disc'>
                                                <li>Payments for rentals must be made through the Service, and the Company will handle payment processing.</li>
                                                <li>Cancellation and refund policies for short-term and mid-term rentals may vary and will be clearly stated in the property listing. Renters must agree to these policies before making a reservation.</li>
                                                <li>In the event of a dispute between a property owner and a renter, the Company may, at its discretion, mediate the dispute but is not obligated to do so.</li>
                                            </ul>
                                        </div>

                                        <br />
                                        <li className='font-bold'>Fees</li>
                                        <p>The Company may charge fees for its services. You will be informed of any fees prior to using the Service. You agree to pay all fees in a timely manner and to provide accurate and complete billing information. Failure to pay fees may result in the suspension or termination of your account.</p>
                                        
                                        <br />
                                        <li className='font-bold'>Modification of Service</li>
                                        <p>The Company reserves the right to modify or discontinue the Service, or any part of it, at any time without notice. The Company will not be liable to you or any third party for any modification or discontinuance of the Service.</p>

                                        <br />
                                        <li className='font-bold'>Termination</li>
                                        <p>The Company reserves the right to terminate your access to the Service at any time, for any reason, and without notice. Upon termination, you will no longer have access to your account and any Content associated with your account may be deleted by the Company.</p>

                                        <br />
                                        <li className='font-bold'>Disclaimer of Warranties</li>
                                        <p>THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. THE COMPANY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. THE COMPANY MAKES NO WARRANTY THAT THE SERVICE WILL MEET YOUR REQUIREMENTS, OR THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE.</p>

                                        <br />
                                        <li className='font-bold'>Limitation of Liability</li>
                                        <p>IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICE, INCLUDING BUT NOT LIMITED TO LOSS OF DATA, BUSINESS INTERRUPTION, OR PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, WHETHER OR NOT THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

                                        <br />
                                        <li className='font-bold'>Indemnification</li>
                                        <p>You agree to indemnify and hold the Company, its affiliates, officers, agents, and employees, harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of the Service, your violation of this Agreement, or your violation of any rights of another.</p>

                                        <br />
                                        <li className='font-bold'>Governing Law</li>
                                        <p>This Agreement shall be governed by and construed in accordance with the laws of the state of California, without giving effect to any principles of conflicts of law.</p>

                                        <br />
                                        <li className='font-bold'>Entire Agreement</li>
                                        <p>This Agreement constitutes the entire agreement between you and the Company regarding the use of the Service, superseding any prior agreements between you and the Company.</p>

                                        <br />
                                        <li className='font-bold'>Contact Information</li>
                                        <p>If you have any questions or comments about these Terms of Use, please contact us at <a href="mailto:concierge@modernday.space" className='font-semibold hover:underline'>concierge@modernday.space</a>.</p>

                                        <br />
                                        <li className='font-bold'>User Responsibilities</li>
                                        <div>
                                            As a user of the Service, you agree to:
                                            <p className="md:ml-4"><span className='font-semibold'>a.</span> Comply with all applicable laws and regulations regarding your use of the Service.</p>
                                            <p className="md:ml-4"><span className='font-semibold'>b.</span> Provide accurate and complete information when creating your account and using the Service.</p>
                                            <p className="md:ml-4"><span className='font-semibold'>c.</span> Use the Service only for its intended purpose and in a lawful manner.</p>
                                            <p className="md:ml-4"><span className='font-semibold'>d.</span> Protect your account login information and notify the Company immediately if you suspect any unauthorized access to your account.</p>
                                            <p className="md:ml-4"><span className='font-semibold'>e.</span> Not engage in any activity that could damage, disable, overburden, or impair the Service or its infrastructure.</p>
                                            <p className="md:ml-4"><span className='font-semibold'>f.</span> Not attempt to gain unauthorized access to the Service, other user accounts, or the Company's computer systems or networks.</p>
                                            <p className="md:ml-4"><span className='font-semibold'>g.</span> Cooperate with any investigation by the Company into your use of the Service or any suspected violation of these Terms of Use.</p>
                                            <br />
                                            By using the Service, you acknowledge that you have read and agree to abide by these User Responsibilities, as well as the additional terms related to listing and renting properties.</div>
                                        </ol>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        </div>
                    </div>
                </>
            </Dialog>
        </Transition>
    )
}

export default Terms