import ContactForm from "../components/ContactForm"

const ContactUsBlock = () => {
    return (
        <div className='md:mt-10' id="contact">
            <div className='max-w-screen-xl m-auto py-10 md:py-20'>
                <div className="text-center">
                    <div className="text-center m-auto w-full mb-8">
                        <span className="border border-orange-100 bg-orange-100 rounded-2xl px-4 py-2 text-xs font-bold text-orange-400">Contact Us</span>
                    </div>
                    <h4 className="pb-4 text-sky-900 ">Let's have a chat!</h4>
                    <p className="text-sky-900">Need assistance or ready to make a reservation? Contact us.</p>
                </div>
                <div className="max-w-screen-lg m-auto"> 
                    <div className="md:grid md:grid-cols-5 mt-6">
                        <div className="col-span-2">
                            <img className="m-auto w-full md:rounded-bl-xl md:rounded-tr-xl h-full object-cover" src={require("../assets/patio.jpg")} alt="" />
                        </div>
                        <div className="col-span-3">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUsBlock