import Logo from "../navigation/Logo"
import { NavigationMenuType } from '../Header'

interface MenuInterface {
    NavigationMenu: NavigationMenuType[]
    setIsMobileOpen: any
    isHeaderFixed: boolean
}

const Menu = ({ NavigationMenu, setIsMobileOpen, isHeaderFixed }: MenuInterface) => {
    return (
        <nav className={`relative top-0 px-4 ${isHeaderFixed ? "py-2" : "py-4"} flex justify-between items-center bg-transparent`}>
            <a className="" href="/">
                <Logo className="h-10"/>
            </a>

            <div className="lg:hidden">
                <button className="navbar-burger flex items-center text-blue-600 p-3" onClick={() =>setIsMobileOpen(true)}>
                    <svg
                    className="block h-4 w-4 fill-current"
                    fill="#0c4a6e"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                        <title>Mobile menu</title>
                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                    </svg>
                </button>
            </div>

            <ul className="hidden absolute top-1/2 right-0 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-6 pr-12">
                {NavigationMenu.map((menu: any) =>  
                    <li key={menu.name}>
                        {/* <a className="text-sm text-blue-600 font-bold" href="#">
                        About Us
                        </a> */}
                        <a className="text-sm text-sky-700 hover:text-sky-500 px-5 font-semibold" href={menu.href}>
                            {menu.name}
                        </a>
                    </li>
                )}
            </ul>

            <a className="hidden lg:inline-block py-2.5 px-4 border-2 border-sky-500 hover:bg-sky-500 hover:text-white text-xs text-sky-500 font-bold rounded transition duration-200"
            href="#contact">Get Connected</a>
        </nav>
    )
}

export default Menu