const AboutUsBlock = () => {
    return (
        <div className='md:mt-10' id="about-us">
            <div className='max-w-screen-xl m-auto md:pt-20'>
                <div className="text-center m-auto w-full mb-4">
                    <span className="border border-orange-100 bg-orange-100 rounded-2xl px-4 py-2 text-xs font-bold text-orange-400">About Us</span>
                </div>
                <h5 className="md:py-3 text-center">Modern Service of the Best </h5>
                <p className="px-4 md:px-0 md:w-3/5 m-auto text-center text-sm leading-7 tracking-wider mb-8">Whether you're visiting the city for business or pleasure, a special event or a romantic getaway, our short-term rentals offer the perfect blend of comfort, luxury, and convenience. We invite you to experience the best of the best and make memories that will last a lifetime.</p>
                <img className="m-auto w-full md:rounded-md max-h-[500px] object-cover" src={require("../assets/hero02.jpg" )}alt="" />
            </div>
            <div className='px-4 md:px-0 max-w-screen-lg m-auto mt-4 md:mt-16'>
                <h5 className="py-3 text-xl md:text-2xl text-center md:w-3/5 m-auto">Rent with confidence, knowing that safety and comfort are our top priorities</h5>
                <div className="md:grid md:grid-cols-5 text-center md:text-left mt-6">
                    <div className="col-span-3 md:w-4/5">
                        <h4 className="mb-4 text-2xl md:text-3xl">What can you look expect from us during your stay with us?</h4>
                        <p className=" text-sm leading-7 tracking-wider mb-8">
                        Our properties are hand-picked for their prime locations, stunning architecture, and breathtaking views. Each rental is fully furnished with the finest linens, fabrics, and finishes, and decorated with an expert eye for style and detail. From gourmet kitchens to spa-like bathrooms, every aspect of our rentals is designed to provide the ultimate in comfort and indulgence.
                        <br />
                        <br />
                        In addition to the luxurious amenities of the rental properties themselves, our guests also have access to a wide range of services and perks. Our concierge team is available to assist with anything you may need, from transportation arrangements to reservations at the city's hottest restaurants and clubs. We can also arrange for private chefs, personal trainers, and other VIP services to make your stay as pampered and indulgent as possible.
                        </p>
                    </div>
                    <div className="col-span-2">
                        <img className="m-auto w-full md:rounded-br-xl md:rounded-tl-xl h-full object-cover" src={require("../assets/patio02.jpg")} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUsBlock 