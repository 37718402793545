import { Carousel } from './../components/Carousel';
import { CarouselVertical } from './../components/CarouselVertical';

const words = ['Modern', 'Comfort', 'Refined', 'Elegance', 'Sophisticated']


const CarouselBlock = () => {
    return (
        <div className='px-4 md:px-0 mt-10 md:mt-20' id="listings">
            <div className='max-w-screen-xl m-auto mb-6 md:pt-20'>
                <h5 className='text-xl md:text-3xl flex items-center'>
                    <span>Rent the height of </span>
                    <span className='ml-1.5 md:ml-2 mb-[0.75px] md:mb-0 text-orange-600 font-bold w-[40%] md:w-1/2'>
                        <CarouselVertical words={words} />
                    </span>
                </h5>
                {/* <p className='italic text-xs text-gray-400'>*Rates subject to change and based on average daily rates*</p>             */}
            </div>
            <Carousel />
        </div>
    )
}

export default CarouselBlock