import { useState } from 'react'
import { useForm } from "react-hook-form"
import { HomeSVG } from "src/assets/HomeSVG"

type EmailFormType = {
  email: string
}

const defaultValues: EmailFormType = {
  email: "",
}

const HeroBlock = () => {
  const [validError, setValidError] = useState<boolean>(true)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues })
  
  const onSubmit = async(data: EmailFormType) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }

    const response = await fetch('https://api.modernday.space/api/contact', requestOptions)
    .catch((error) => {
      console.error('Error submitting e-mail form:', error)
      setValidError(true)
    })
    const result = await response?.json()
    console.log(result)
    // reset() // reset the form after successful submission
    
  }

  const onError = (errors: any) => {
    console.log(errors)
    if (Object.getOwnPropertyNames(errors).length) {
      setValidError(false)
      setTimeout(() => {
        setValidError(true)
      }, 3000)
    }
  }


  return (
    <div className="h-[23em] md:h-[35em] bg-sky-50 md:pt-14">
      <div className="max-w-screen-xl m-auto md:px-4 relative">
      <div className="md:hidden w-full h-full bg-black absolute top-0 opacity-50"/>
        <div className="md:grid md:grid-rows-2 md:grid-cols-9 md:gap-4 w-full space-between">
          <div className=" z-10 absolute md:relative top-1/4 md:top-0 row-span-1 col-span-4 justify-self-start place-self-end w-full">
            <h2 className="text-white md:text-black px-4 md:px-0 text-3xl md:text-4xl">
              Elegance meets comfort: <br/>Perfect Living
            </h2>
          </div>

          <div className="mb-4 md:mb-0 row-span-2 col-start-5 col-end-10">
            <div className="md:grid md:grid-cols-5">
              <div className="hidden md:flex gap-4 w-[60%] ml-auto mr-4">
                {Array(4)
                  .fill(0)
                  .map((dotsX, idx) => (
                    <div key={idx}>
                      {Array(12)
                        .fill(0)
                        .map((dotsY, idx) => (
                          <div
                            key={idx}
                            className="border-4 rounded-xl border-sky-200 w-2 h-2 mb-4"
                          />
                        ))}
                    </div>
                  ))}
              </div>
              <div className="col-span-4">
                <img
                  className="md:rounded-tr-2xl md:rounded-bl-2xl"
                  src={require("../assets/hero01.jpg")}
                  alt="house"
                />
              </div>
            </div>
          </div>

          <form className="px-4 md:px-0 absolute md:relative md:top-0 top-[47%] row-span-2 col-span-4 justify-self-start w-full" onSubmit={handleSubmit(onSubmit, onError)} noValidate>
            {!validError && (
              <div
                className="fixed top-16 md:top-20 z-50 left-1/2 text-center text-sm md:text-base w-11/12 md:w-1/2 -translate-x-2/4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded"
                role="alert"
              >
                <span className="block">Please enter a valid E-mail Address.</span>
              </div>
            )}
            <div className="block w-full">
              <div className="relative">
                <div className="absolute top-5 left-3">
                  <HomeSVG color="#9ca3c1" height={15} width={15} />
                </div>
              </div>
            </div>
            <input
              id="email"
              className={`text-sm md:text-base mb-2 h-14 w-full pl-10 pr-20 rounded-sm shadow-md z-0 focus:shadow focus:outline-none ${errors.email?.type === "required" && "border-red-400 focus:border-red-400"}`}
              placeholder="Enter your email address"
              {...register("email", {
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Please enter a valid email",
                },
                required: true
              })}
              type="email"
            />
            <div className="absolute top-2 right-6 md:right-2">
              <button className="h-10 w-28 font-semibold text-white rounded-sm bg-orange-600 hover:bg-orange-700" type="submit">
                Subscribe
              </button>
            </div>
            <p className="text-xs w-full md:w-96 text-white md:text-gray-500 md:italic leading-4 md:leading-6">
              Stay ahead of the curve and be the first to know about exclusive
              deals and new developments. Sign up for our updates today!
            </p>
          </form>
        </div>
      </div>
    </div>
  )
}

export default HeroBlock
