import Facebook from './../assets/Facebook'
import Pinterest from './../assets/Pinterest'
import Instagram from './../assets/Instagram'
import Terms from './Terms'
import Privacy from './Privacy';
import { useState } from 'react';

const Footer = () => {
    const [termsOpen, setTermsOpen] = useState<boolean>(false)
    const [privacyOpen, setPrivacyOpen] = useState<boolean>(false)


    return (
        <div className="bg-gray-100 py-6 px-6 md:px-0">
            <div className="max-w-screen-xl m-auto text-sky-900">
                <div className="md:flex w-full place-content-between text-center md:text-left">
                    <ul className="grid grid-cols-3 text-sm">
                        <li><span className="text-xs">Copyright © 2021</span></li>
                        <li>
                            <button className="hover:underline" onClick={() => setPrivacyOpen(true)}>Privacy Policy</button>
                        </li>
                        <li className="border-l border-sky-900 px-2 md:px-4">
                            <button className="hover:underline" onClick={() => setTermsOpen(true)}>Terms of Use</button>
                        </li>
                        {/* <li className="px-2 md:px-4"><a href="#">Newsletter</a></li> */}
                    </ul> 
                    <div className="mt-6 mb-4 md:my-0 flex place-items-center md:w-[8%] place-content-evenly md:place-content-between">
                        <div>
                            <a href="https://www.facebook.com/modernday.space"  rel="noreferrer"><Facebook /></a>
                        </div>
                        <div>
                            <a href="https://www.instagram.com/modernday.space" target="_blank"  rel="noreferrer"><Instagram /></a>
                        </div>
                        <div>
                            <a href="https://www.pinterest.com/modernday_space" target="_blank" rel="noreferrer"><Pinterest /></a>
                        </div>
                    </div>
                </div>
                <Terms 
                    termsOpen={termsOpen}
                    setTermsOpen={setTermsOpen}
                />
                <Privacy 
                    privacyOpen={privacyOpen}
                    setPrivacyOpen={setPrivacyOpen}
                />
            </div>
        </div>
    )
}

export default Footer