import './styles/App.css'

import Layout from './components/Layout'
import HeroBlock from './blocks/HeroBlock'
import CarouselBlock from './blocks/CarouselBlock'
import AboutUsBlock from './blocks/AboutUsBlock'
import ContactUsBlock from './blocks/ContactUsBlock'

function App() {
  return (
    <Layout>
      <HeroBlock/>
      <CarouselBlock/>
      <AboutUsBlock/>
      <ContactUsBlock/>
    </Layout>
  );
}

export default App;
