import { useState } from "react";
import { Transition } from "@headlessui/react";
import Facebook from "../../assets/Facebook";
import Pinterest from "../../assets/Pinterest";
import Instagram from "../../assets/Instagram";
import Logo from "../navigation/Logo";
import { NavigationMenuType } from "../Header";
import Terms from "./../Terms";

interface MobileMenuInterface {
  NavigationMenu: NavigationMenuType[];
  isMobileOpen: boolean
  setIsMobileOpen: any
}

const MobileMenu = ({
  NavigationMenu,
  isMobileOpen,
  setIsMobileOpen,
}: MobileMenuInterface) => {
  const [termsOpen, setTermsOpen] = useState(false);
  const closeNav = () => {
    setIsMobileOpen(false);
  };

  const toggleTerms = () => {
    setIsMobileOpen(false);
    setTermsOpen(true);
  };

  return (
    <Transition
      className="navbar-menu z-50 fixed w-full h-full top-0"
      show={isMobileOpen}
      enter="transition-all ease-out duration-500"
      enterFrom="transform -translate-x-full"
      enterTo="transform translate-x-0"
      leave="transition-all ease-in duration-500"
      leaveFrom="transform translate-x-0"
      leaveTo="transform -translate-x-full"
    >
      <div
        className={`navbar-backdrop fixed inset-0 bg-gray-800 opacity-25 ${
          !isMobileOpen && "hidden"
        }`}
        onClick={closeNav}
      />
      <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
        <div className="flex items-center mb-8">
          <a className="mr-auto text-3xl font-bold leading-none" href="/">
            <Logo className="h-12" />
          </a>

          <button className="navbar-close" onClick={closeNav}>
            <svg
              className="h-6 w-6 text-sky-900 cursor-pointer hover:text-gray-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="#0c4a6e"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div>
          <ul>
            {NavigationMenu.map((menu: any) => (
              <li className="mb-1" key={menu.name}>
                <a
                  className="block p-4 pl-0 text-base font-semibold text-sky-900 hover:bg-sky-50 hover:text-sky-600 rounded"
                  href={menu.href}
                  onClick={closeNav}
                >
                  {menu.name}
                </a>
              </li>
            ))}
            <li className="mb-1">
              <button
                className="block p-4 pl-0 text-base font-semibold text-sky-900 hover:bg-sky-50 hover:text-sky-600 rounded"
                onClick={toggleTerms}
              >
                Terms of Use
              </button>
            </li>
            <li className="mb-1">
              <button
                className="block p-4 pl-0 text-base font-semibold text-sky-900 hover:bg-sky-50 hover:text-sky-600 rounded"
                onClick={closeNav}
              >
                Privacy Policy
              </button>
            </li>
          </ul>
        </div>

        <div className="mt-auto">
          <div className="pt-6">
            <a
              className="block px-4 py-3 border-2 text-center border-sky-500 hover:bg-sky-500 hover:text-white text-sm text-sky-500 font-bold rounded"
              href="/"
            >
              Get Connected
            </a>
          </div>

          <div className="mt-6 mb-4 md:my-0 flex place-items-center md:w-[8%] place-content-evenly md:place-content-between">
            <a href="#">
              <Facebook />
            </a>
            <a href="#">
              <Instagram />
            </a>
            <a href="#">
              <Pinterest />
            </a>
          </div>

          <p className="my-4 text-xs text-center text-sky-900">
            <span>Copyright © 2021</span>
          </p>
        </div>
      </nav>
      <Terms termsOpen={termsOpen} setTermsOpen={setTermsOpen} />
    </Transition>
  );
};

export default MobileMenu;
