import 'react-input-mask/dist/react-input-mask.min.js'
import { useState } from "react"
import { useForm } from "react-hook-form"
import InputMask from 'react-input-mask'

type ContactFormType = {
  full_name: string
  email: string
  phone_number: string
  message: string
}

const defaultValues: ContactFormType = {
  full_name: "",
  email: "",
  phone_number: "",
  message: "",
}

const ContactForm = () => {
  const [validError, setValidError] = useState<boolean>(true)
  const [phoneNumberValid, setPhoneNumberValid] = useState<boolean>(true)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues })

  const onSubmit = async(data: ContactFormType) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }

    const response = await fetch('https://api.modernday.space/api/contact', requestOptions)
    .catch((error) => {
      console.error('Error submitting form:', error)
      setValidError(true)
    })
    const result = await response?.json()
    console.log(result)
  }

  const handleBlur = (e: any) => {
    if (!(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(e.target.value)) {
        setPhoneNumberValid(false)
    } else {
        setPhoneNumberValid(true)
    }
  }


  const onError = (errors: any) => {
    console.log(errors)
    if (Object.getOwnPropertyNames(errors).length) {
      setValidError(false)
      setTimeout(() => {
        setValidError(true)
      }, 3000)
    }
  }

  return (
    <div className="mx-auto max-w-xl mt-6">
      <form onSubmit={handleSubmit(onSubmit, onError)} noValidate>
        {!validError && (
          <div
            className="fixed top-16 md:top-20 z-50 left-1/2 text-center text-sm md:text-base w-11/12 md:w-1/2 -translate-x-2/4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded"
            role="alert"
          >
            <span className="block">Please complete all required fields.</span>
          </div>
        )}
        <div className="bg-white rounded px-6 md:px-12 md:py-10 mb-4 flex flex-col">
          <div className="-mx-3 mb-6">
            <div className="w-full px-3 pb-3">
              <label
                className="uppercase tracking-wide text-sky-900 text-xs font-bold mb-2"
                htmlFor="company"
              >
                Name *
              </label>
              <input
                className={`w-full bg-gray-100 text-sky-900 border ${
                  errors.full_name?.type === "required" &&
                  "border-red-400 focus:border-red-400"
                } rounded-sm py-3 px-4 mb-3 focus:outline-none focus:bg-white focus:border-sky-700`}
                type="text"
                placeholder="Enter full name"
                {...register("full_name", {
                  required: true,
                  maxLength: 155,
                })}
              />
            </div>
            <div className="w-full px-3 pb-3">
              <label
                className="uppercase tracking-wide text-sky-900 text-xs font-bold mb-"
                htmlFor="title"
              >
                Email Address *
              </label>
              {errors.email && (
                <p className="text-red-700 text-xs pb-1">
                  {errors.email.message}
                </p>
              )}
              <input
                id="email"
                className={`w-full bg-gray-100 text-sky-900 border border-gray-200 ${
                  errors.email?.type === "required" &&
                  "border-red-400 focus:border-red-400"
                } rounded-sm py-3 px-4 mb-3 focus:outline-none focus:bg-white focus:border-sky-700`}
                placeholder="Enter email address"
                {...register("email", {
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Please enter a valid email",
                  },
                  required: true
                })}
                type="email"
              />
            </div>
            <div className="w-full px-3 pb-3">
              <label
                className="uppercase tracking-wide text-sky-900 text-xs font-bold mb-2"
                htmlFor="title"
              >
                Phone Number
              </label>
              {!phoneNumberValid  && <p className="text-red-700 text-xs pb-1">Please enter a valid phone number.</p>}
              <InputMask
                className="w-full bg-gray-100 text-sky-900 border border-gray-200rounded-sm py-3 px-4 mb-3 focus:outline-none focus:bg-white focus:border-sky-700"
                placeholder="Enter phone number"
                mask="(999) 999-9999"
                {...register("phone_number")}
                onBlur={handleBlur}
              />
            </div>
            <div className="md:w-full px-3">
              <label
                className="uppercase tracking-wide text-sky-900 text-xs font-bold mb-2"
                htmlFor="application-link"
              >
                Message/ Question *
              </label>
              <textarea
                className={`w-full bg-gray-100 text-sky-900 border border-gray-200 ${
                  errors.message?.type === "required" &&
                  "border-red-400 focus:border-red-400"
                } rounded-sm py-3 px-4 mb-3 focus:outline-none focus:bg-white focus:border-sky-700`}
                rows={4}
                {...register("message", { required: true, minLength: 10 })}
              />
            </div>
          </div>
          <div className="w-full">
            <button
              className="bg-sky-700 hover:bg-sky-600 text-white font-bold py-2 px-4 w-full rounded-sm focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
