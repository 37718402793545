import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const images = [
    { name: "Los Angeles, CA", price: 264, short: "los-angeles" },
    { name: "Irvine, CA", price: 243, short: "irvine" },
    { name: "Temecula, CA", price: 463, short: "redwood" }
  // { name: 'Austin, TX', price: 275, short: "austin"},
  // { name: 'Scottsdale, AZ', price: 327, short: "scottsdale"},
  // { name: 'Orlando, FL', price: 198, short: "orlando"},
  // { name: 'Denver, CO', price: 390, short: "denver"},
];

export const Carousel = () => {
  const options = {
    dots: false,
    autoplay: true,
    center: true,
    startPosition: 0,
    margin: 10,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3.5,
      },
      1000: {
        items: 3.5,
      },
    },
  };
  return (
    <OwlCarousel {...options}>
      {images.map((image, idx) => (
        <div className="item" key={idx}>
          <div className="card !p-0">
            <div className="img-card cursor-pointer relative mix-blend-overlay">
              <img
                className="!h-72 h-14 bg-gradient-to-r from-cyan-500 to-blue-500"
                src={require(`../assets/${image.short}.jpg`)}
                alt={image.name}
              />
              <div className="absolute bottom-0 z-3 bg-gradient-to-t from-gray-900 via-gray-900 to-transparent h-20 w-full opacity-70 rounded-b-md flex flex-col items-start">
                <h6 className="text-white px-4 mt-2 mb-0">{image.name}</h6>
                {idx !== 0 && (
                  <p className="text-white px-4 py-2 text-xs">Coming soon</p>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </OwlCarousel>
  );
};
