import { Dialog , Transition } from '@headlessui/react'
import { Fragment, useRef, useEffect } from 'react'

type PrivacyType = {
    privacyOpen: boolean
    setPrivacyOpen?: any
}

const Privacy = ({ privacyOpen, setPrivacyOpen }: PrivacyType) => {
    const dialogRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        console.log(privacyOpen)
        if (dialogRef.current) {
          dialogRef.current.scrollTop = 0
        }
      }, [privacyOpen])

        return (
        <Transition appear show={privacyOpen} as={Fragment}>
            <Dialog 
                as="div" 
                className="relative top-0" 
                onClose={() => setPrivacyOpen(false)} 
                ref={dialogRef}
                initialFocus={dialogRef}
            >
                privacyOpen && <>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25 z-50"/>
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto bottom-5 top-5 z-50 ">
                        <div className="flex items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="modal w-full max-w-2xl transform  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all overflow-y-scroll max-h-[500px] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <h3
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                Privacy Policy
                            </h3>
                            <div className="mt-2">
                                <div className="text-sm text-gray-500 leading-6">
                                    <p>At Seven Sleeves LLC DBA Modern Day, we are committed to protecting the privacy of our clients and website visitors. This privacy policy outlines the type of personal information we collect and how we use and protect it.</p>
                                    <br />
                                    <ol className='list-inside list-decimal'>
                                        <li className='font-bold'>Information Collected</li>
                                        <p>We may collect the following personal information from our clients or website visitors:</p>
                                        <br />
                                        <div className='md:ml-4'>
                                            <ul className='list-inside list-disc'>
                                                <li>Name, address, phone number, email address</li>
                                                <li>Payment information, including credit card details</li>
                                                <li>Property details, including location, occupancy information, and rental terms</li>
                                                <li>Communication preferences and feedback</li>
                                            </ul>
                                        </div>

                                        <br />
                                        <li className='font-bold'>Use of Information</li>
                                        <p>We use the personal information collected from our clients or website visitors to:</p>
                                        <div className='md:ml-4'>
                                            <ul className='list-inside list-disc'>
                                                <li>Provide short-term and mid-term rental and property management services</li>
                                                <li>Process payments and issue invoices</li>
                                                <li>Respond to inquiries, requests, and feedback</li>
                                                <li>Improve our services and website experience</li>
                                                <li>Comply with legal requirements and protect our legal rights</li>
                                            </ul>
                                        </div>
                                        
                                        <br />
                                        <li className='font-bold'>Protection of Information</li>
                                        <p>We take appropriate measures to protect the personal information collected from our clients or website visitors. This includes:</p>
                                        <div className='md:ml-4'>
                                            <ul className='list-inside list-disc'>
                                                <li>Using secure technology to process and store personal information</li>
                                                <li>Limiting access to personal information to authorized personnel only</li>
                                                <li>Implementing policies and procedures to safeguard personal information</li>
                                            </ul>
                                        </div>

                                        <br />
                                        <li className='font-bold'>Sharing of Information</li>
                                        <p>Seven Sleeves LLC DBA Modern Day does not share any personal information collected from our clients or website visitors with third-party service providers or law enforcement agencies, except where required by law or to protect our legal rights, or where necessary for the provision of our services, such as sharing property details with potential renters.</p>

                                        <br />
                                        <li className='font-bold'>Cookies</li>
                                        <p>We may use cookies on our website to collect non-personal information such as IP addresses, browser type, and website usage. This information is used to improve our website experience and understand website traffic patterns.</p>

                                        <br />
                                        <li className='font-bold'>Third-Party Websites</li>
                                        <p>Our website may contain links to third-party websites that are not under our control. We are not responsible for the privacy practices of these websites and encourage our clients and website visitors to review their respective privacy policies.</p>

                                        <br />
                                        <li className='font-bold'>Changes to Privacy Policy</li>
                                        <p>We reserve the right to modify or update this privacy policy at any time without prior notice. The updated privacy policy will be posted on our website, and we encourage our clients and website visitors to review it periodically.</p>
                                        <br />
                                       
                                        <p>
                                            If you have any questions or concerns about our privacy policy, please contact us at <a href="mailto:concierge@modernday.space" className='font-semibold hover:underline'>concierge@modernday.space</a>.
                                        </p>
                                        </ol>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        </div>
                    </div>
                </>
            </Dialog>
        </Transition>
    )
}

export default Privacy