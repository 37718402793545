import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export const CarouselVertical = ({ words }: { words: string[] }) => {
  const settings = {
    infinite: true,
    autoplay: true,
    arrows: false,
    vertical: true,
    slidesToShow: 1,
  }

  return (
      <Slider {...settings}>
        {words.map((word, idx) =>
            <div key={idx}>
                {word}
            </div>
        )}
      </Slider>
  )
}
