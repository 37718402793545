import { ComponentPropsWithoutRef } from 'react'
import mainLogo from '../../assets/Logo-sm.png'

export interface LogoProps extends ComponentPropsWithoutRef<'div'> {
}

const Logo = ({ className }: LogoProps) => {
    return (
        <img  src={mainLogo} className={className} alt="fireSpot"/>
    )
}
export default Logo