import { useState, useEffect, useRef } from 'react'

import Menu from "./navigation/Menu"
import MobileMenu from "./navigation/MobileMenu"

export type NavigationMenuType = {
  name: string
  href: string
}

const NavigationMenu = [
    { name: 'About', href: '#about-us' },
    { name: 'Listings', href: '#listings' },
]

const Header = () => {
  const [isMobileOpen, setIsMobileOpen] = useState(false)
  const [isHeaderFixed, setIsHeaderFixed] = useState(false)
  const headerRef = useRef<any>(null)

  useEffect(() => {}, [setIsMobileOpen])

  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = headerRef.current.clientHeight;
      if (window.pageYOffset > headerHeight) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [headerRef]);

  return (
    <div ref={headerRef} className={`bg-sky-50 transition-colors duration-300 ease-in-out ${isHeaderFixed ? "w-[101%] bg-white fixed -top-px z-50 border-b border-sky-50 shadow-md": "w-full"}`}> 
        <div className="max-w-screen-xl m-auto">
            <Menu 
              NavigationMenu={NavigationMenu} 
              isHeaderFixed={isHeaderFixed} 
              setIsMobileOpen={setIsMobileOpen}/>
            <MobileMenu 
              NavigationMenu={NavigationMenu}
              isMobileOpen={isMobileOpen}
              setIsMobileOpen={setIsMobileOpen}/>
        </div>
    </div>
  )
};

export default Header